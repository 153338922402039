



















































































import useNumber from "@/use/number";
import useUser from "@/use/user";
import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
} from "@vue/composition-api";
import { watchDebounced } from "@vueuse/shared";
import { AxiosInstance } from "axios";
import { nextTick } from "process";

const inventoryTypes = ["occupied", "available", "pool"] as const;

export default defineComponent({
  components: {
    MUserInput: () => import("@/components/molecules/m-user-input.vue"),
    MInventoryVehicleAssignments: () =>
      import("@/components/molecules/m-inventory-vehicle-assignments.vue"),
  },
  props: {
    type: {
      type: String as PropType<typeof inventoryTypes[number]>,
      required: false,
      default: "occupied",
      validator: (value: typeof inventoryTypes[number]) =>
        inventoryTypes.includes(value),
    },
    groupByCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: String,
      required: false,
      default: null,
    },
    company: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props, { root, emit }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });
    const { formatNumber } = useNumber();

    const type = computed(() => props.type);
    const search = computed(() => props.search);
    const company = computed(() => props.company);

    const loading = ref(false);
    const items = ref([]);
    const total = ref(0);

    const headers = computed(() => [
      { value: "name", text: "Marka/model", sortable: false },
      {
        value: "registrationNumber",
        text: "Numer rejestracyjny",
        sortable: false,
      },
      { value: "vinNumber", text: "Numer VIN", sortable: false },
      { value: "mileage", text: "Przebieg", sortable: false },
      ...(props.type === "occupied"
        ? [{ value: "user", text: "Użytkownik", sortable: false }]
        : []),
      { value: "action", text: "", width: 1, sortable: false, align: "right" },
    ]);

    const options = computed({
      get: () => ({
        page:
          parseInt(
            root.$route.query[`vehicles.${props.type}.page`] as string
          ) || 1,
        itemsPerPage:
          parseInt(
            root.$route.query[`vehicles.${props.type}.itemsPerPage`] as string
          ) || 10,
      }),
      set: (value) => {
        root.$router
          .replace({
            query: {
              ...root.$route.query,
              [`vehicles.${props.type}.page`]: value.page.toString(),
              [`vehicles.${props.type}.itemsPerPage`]: value.itemsPerPage.toString(),
            },
          })
          .catch(() => []);
      },
    });

    const showExpand = computed(() =>
      ["occupied", "available"].includes(props.type)
    );

    const groupBy = computed(() =>
      props.groupByCompany ? "company.id" : null
    );

    const fetch = () => {
      loading.value = true;

      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get("inventory/vehicle", {
          params: {
            type: type.value || undefined,
            search: search.value || undefined,
            company: company.value || undefined,
            page: options.value.page || undefined,
            itemsPerPage: options.value.itemsPerPage || undefined,
          },
        })
        .then(({ data: { vehicles, total: totalVehicles } }) => {
          items.value = vehicles;
          total.value = totalVehicles;
        })
        .catch(() => {
          items.value = [];
          total.value = 0;
        })
        .finally(() => (loading.value = false));
    };

    watchDebounced([type, search, options, company], fetch, {
      deep: true,
      immediate: true,
      debounce: 500,
      maxWait: 5000,
    });

    const refresh = () => fetch();

    const assignDialog = reactive({
      key: new Date().getTime(),
      isOpen: false,
      model: {
        vehicle: null as any,
        company: null as string | null,
        user: null as any,
      },
    });

    const closeDialog = () => {
      assignDialog.model.vehicle = null;
      assignDialog.model.company = null;
      assignDialog.model.user = null;

      nextTick(() => {
        assignDialog.isOpen = false;
        assignDialog.key = new Date().getTime();
      });
    };

    const findUser = (vehicle: any, company: string) => {
      assignDialog.model.vehicle = vehicle;
      assignDialog.model.company = company;
      nextTick(() => (assignDialog.isOpen = true));
    };

    const assignUser = () => {
      emit("action", {
        type: "vehicleHandover",
        user: assignDialog.model.user,
        vehicle: assignDialog.model.vehicle,
        step: 4,
      });
      nextTick(closeDialog);
    };

    return {
      hasAccessTo,
      hasManyCompanies,
      formatNumber,

      loading,
      items,
      total,
      headers,
      options,
      showExpand,
      groupBy,

      refresh,

      assignDialog,
      closeDialog,
      findUser,
      assignUser,
    };
  },
});
